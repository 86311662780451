var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"app-group-list"},[_c('div',{staticClass:"left"},[_c('a-affix',{attrs:{"offset-top":24}},[_c('div',{staticClass:"frame",style:({ backgroundColor: _vm.form.bgColor })},[_c('div',{staticClass:"preview-body"},[(_vm.form.banner)?_c('img',{staticClass:"banner",attrs:{"src":_vm.form.banner}}):_vm._e(),_c('div',{staticClass:"wrap"},_vm._l((_vm.form.json),function(item){return _c('div',{key:item.sort,staticClass:"row"},[_c('img',{staticClass:"icn",attrs:{"src":item.icon}}),_c('div',{staticClass:"txt"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))])]),_c('div',{staticClass:"btn",class:item.num === 0 ? 'active' : null,style:(_vm.btnStyle(item))},[_vm._v(" "+_vm._s(item.num > 0 ? "加入群聊" : "立即进群")+" ")])])}),0)])])])],1),_c('div',{staticClass:"right"},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('a-page-header',{attrs:{"title":`群裂变（${_vm.form.cnt}）`}},[_c('template',{slot:"extra"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 保存 ")])],1)],2),_c('a-form-model',{ref:"form",attrs:{"model":_vm.form}},[_c('div',{staticClass:"adv-form"},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":10}},[_c('a-form-model-item',{attrs:{"required":"","label":"活动名称","prop":"title","rules":[
                    {
                      required: true,
                      message: '活动名称必填'
                    }
                  ]}},[_c('a-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Banner","help":"建议尺寸：750 x 300","prop":"banner"}},[_c('a-upload',{attrs:{"data":{ index: 888 },"action":_vm.UPLOAD_URL,"headers":{ t: _vm.getToken },"withCredentials":"","accept":"image/*","showUploadList":false,"before-upload":_vm.beforeUpload},on:{"change":_vm.handleUpload}},[(_vm.form.banner)?_c('img',{staticClass:"upload-image-small",attrs:{"src":_vm.form.banner}}):_vm._e(),_c('a-button-group',[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" "+_vm._s(_vm.form.banner ? "更换" : "上传")+"图片 ")],1),(_vm.form.banner)?_c('a-button',{attrs:{"type":"danger"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteImg(888)}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(" 删除 ")],1):_vm._e()],1)],1)],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"背景颜色","required":"","prop":"bgColor","rules":[
                    {
                      required: true,
                      message: '背景颜色必填'
                    }
                  ]}},[_c('color-picker',{model:{value:(_vm.form.bgColor),callback:function ($$v) {_vm.$set(_vm.form, "bgColor", $$v)},expression:"form.bgColor"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":10}},[_c('a-form-model-item',{attrs:{"required":"","label":"分享标题","prop":"shareTitle","rules":[
                    {
                      required: true,
                      message: '分享标题必填'
                    }
                  ]}},[_c('span',{attrs:{"slot":"help"},slot:"help"},[_vm._v("动态替换： "),_c('a-tag',{attrs:{"color":"pink"}},[_vm._v("%昵称%")])],1),_c('a-input',{model:{value:(_vm.form.shareTitle),callback:function ($$v) {_vm.$set(_vm.form, "shareTitle", $$v)},expression:"form.shareTitle"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"help":"建议长宽比：5:4，例如：600 x 480","prop":"shareImg"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 分享封面  "),_c('a-tooltip',{attrs:{"title":"不设置封面时默认使用屏幕截图"}},[_c('a-icon',{attrs:{"type":"question-circle-o"}})],1)],1),_c('a-upload',{attrs:{"data":{ index: 999 },"action":_vm.UPLOAD_URL,"headers":{ t: _vm.getToken },"withCredentials":"","accept":"image/*","showUploadList":false,"before-upload":_vm.beforeUpload},on:{"change":_vm.handleUpload}},[(_vm.form.shareImg)?_c('img',{staticClass:"upload-image-small",attrs:{"src":_vm.form.shareImg}}):_vm._e(),_c('a-button-group',[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" "+_vm._s(_vm.form.shareImg ? "更换" : "上传")+"图片 ")],1),(_vm.form.shareImg)?_c('a-button',{attrs:{"type":"danger"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteImg(999)}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(" 删除 ")],1):_vm._e()],1)],1)],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"按钮颜色","required":"","prop":"btnColor","rules":[
                    {
                      required: true,
                      message: '按钮颜色必填'
                    }
                  ]}},[_c('color-picker',{model:{value:(_vm.form.btnColor),callback:function ($$v) {_vm.$set(_vm.form, "btnColor", $$v)},expression:"form.btnColor"}})],1)],1)],1)],1)]),_c('div',{staticClass:"tool"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addRow}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加群 ")],1)],1)],1),_c('table',{staticClass:"group-items"},[_c('thead',[_c('th',{staticClass:"sort"},[_vm._v("排序")]),_c('th',{staticClass:"icon"},[_vm._v("图标")]),_c('th',{staticClass:"name"},[_vm._v("名称")]),_c('th',{staticClass:"desc"},[_vm._v("描述")]),_c('th',{staticClass:"qrcode"},[_vm._v("二维码")]),_c('th',{staticClass:"num"},[_vm._v(" 分享数 "),_c('a-tooltip',{attrs:{"title":"直接进群请填：0"}},[_c('a-icon',{attrs:{"type":"question-circle-o"}})],1)],1),_c('th',{staticClass:"cnt"},[_vm._v("点击数")]),_c('th',{staticClass:"action"},[_vm._v("操作")])]),_c('draggable',_vm._b({attrs:{"tag":'tbody',"handle":".drag-handler"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.form.json),callback:function ($$v) {_vm.$set(_vm.form, "json", $$v)},expression:"form.json"}},'draggable',{
              animation: 200
            },false),[_vm._l((_vm.form.json),function(item,index){return _c('tr',{key:item.sort,staticClass:"row"},[_c('td',{staticClass:"dragzone",staticStyle:{"text-align":"center"}},[_c('a-icon',{staticClass:"drag-handler",attrs:{"type":"menu"}})],1),_c('td',[_c('a-upload',{attrs:{"data":{ index, idx: 1 },"listType":"picture-card","action":_vm.UPLOAD_URL,"headers":{ t: _vm.getToken },"withCredentials":"","accept":"image/*","showUploadList":false,"before-upload":_vm.beforeUpload},on:{"change":_vm.handleUpload}},[(item.icon)?_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":item.icon}}):_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传 ")])],1)])],1),_c('td',[_c('a-input',{attrs:{"max-length":30},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('td',[_c('a-input',{attrs:{"max-length":50},model:{value:(item.desc),callback:function ($$v) {_vm.$set(item, "desc", $$v)},expression:"item.desc"}})],1),_c('td',[_c('a-upload',{attrs:{"data":{ index, idx: 2 },"listType":"picture-card","action":_vm.UPLOAD_URL,"headers":{ t: _vm.getToken },"withCredentials":"","accept":"image/*","showUploadList":false,"before-upload":_vm.beforeUpload},on:{"change":_vm.handleUpload}},[(item.qrcode)?_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":item.qrcode}}):_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传 ")])],1)])],1),_c('td',[_c('a-input-number',{attrs:{"min":0,"max":100},model:{value:(item.num),callback:function ($$v) {_vm.$set(item, "num", $$v)},expression:"item.num"}})],1),_c('td',[_vm._v(" "+_vm._s(item.cnt)+" ")]),_c('td',[_c('a-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.delRow(index)}}},[_vm._v(" 删除 ")])],1)])}),(_vm.form.json.length <= 0)?_c('tr',[_c('td',{attrs:{"colspan":"8"}},[_c('a-empty')],1)]):_vm._e()],2)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }