<template>
  <Layout>
    <div class="app-group-list">
      <div class="left">
        <a-affix :offset-top="24">
          <div class="frame" :style="{ backgroundColor: form.bgColor }">
            <div class="preview-body">
              <img class="banner" v-if="form.banner" :src="form.banner" />
              <div class="wrap">
                <div v-for="item in form.json" :key="item.sort" class="row">
                  <img class="icn" :src="item.icon" />
                  <div class="txt">
                    <div class="name">{{ item.name }}</div>
                    <div class="desc">{{ item.desc }}</div>
                  </div>
                  <div
                    class="btn"
                    :class="item.num === 0 ? 'active' : null"
                    :style="btnStyle(item)"
                  >
                    {{ item.num > 0 ? "加入群聊" : "立即进群" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-affix>
      </div>

      <div class="right">
        <a-spin :spinning="spinning">
          <a-page-header :title="`群裂变（${form.cnt}）`">
            <template slot="extra">
              <a-button type="primary" @click="submit">
                保存
              </a-button>
            </template>
          </a-page-header>
          <a-form-model :model="form" ref="form">
            <div class="adv-form">
              <a-row :gutter="16">
                <a-col :span="10">
                  <a-form-model-item
                    required
                    label="活动名称"
                    prop="title"
                    :rules="[
                      {
                        required: true,
                        message: '活动名称必填'
                      }
                    ]"
                  >
                    <a-input v-model="form.title" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item
                    label="Banner"
                    help="建议尺寸：750 x 300"
                    prop="banner"
                  >
                    <a-upload
                      :data="{ index: 888 }"
                      :action="UPLOAD_URL"
                      :headers="{ t: getToken }"
                      withCredentials
                      accept="image/*"
                      :showUploadList="false"
                      @change="handleUpload"
                      :before-upload="beforeUpload"
                    >
                      <img
                        v-if="form.banner"
                        class="upload-image-small"
                        :src="form.banner"
                      />
                      <a-button-group>
                        <a-button>
                          <a-icon type="upload" />
                          {{ form.banner ? "更换" : "上传" }}图片
                        </a-button>
                        <a-button
                          @click.stop="deleteImg(888)"
                          v-if="form.banner"
                          type="danger"
                        >
                          <a-icon type="delete" /> 删除
                        </a-button>
                      </a-button-group>
                    </a-upload>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item
                    label="背景颜色"
                    required
                    prop="bgColor"
                    :rules="[
                      {
                        required: true,
                        message: '背景颜色必填'
                      }
                    ]"
                  >
                    <color-picker v-model="form.bgColor" />
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-row :gutter="16">
                <a-col :span="10">
                  <a-form-model-item
                    required
                    label="分享标题"
                    prop="shareTitle"
                    :rules="[
                      {
                        required: true,
                        message: '分享标题必填'
                      }
                    ]"
                  >
                    <span slot="help"
                      >动态替换：
                      <a-tag color="pink">%昵称%</a-tag>
                    </span>
                    <a-input v-model="form.shareTitle" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item
                    help="建议长宽比：5:4，例如：600 x 480"
                    prop="shareImg"
                  >
                    <span slot="label">
                      分享封面&nbsp;
                      <a-tooltip title="不设置封面时默认使用屏幕截图">
                        <a-icon type="question-circle-o" />
                      </a-tooltip>
                    </span>
                    <a-upload
                      :data="{ index: 999 }"
                      :action="UPLOAD_URL"
                      :headers="{ t: getToken }"
                      withCredentials
                      accept="image/*"
                      :showUploadList="false"
                      @change="handleUpload"
                      :before-upload="beforeUpload"
                    >
                      <img
                        v-if="form.shareImg"
                        class="upload-image-small"
                        :src="form.shareImg"
                      />
                      <a-button-group>
                        <a-button>
                          <a-icon type="upload" />
                          {{ form.shareImg ? "更换" : "上传" }}图片
                        </a-button>
                        <a-button
                          @click.stop="deleteImg(999)"
                          v-if="form.shareImg"
                          type="danger"
                        >
                          <a-icon type="delete" /> 删除
                        </a-button>
                      </a-button-group>
                    </a-upload>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item
                    label="按钮颜色"
                    required
                    prop="btnColor"
                    :rules="[
                      {
                        required: true,
                        message: '按钮颜色必填'
                      }
                    ]"
                  >
                    <color-picker v-model="form.btnColor" />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
          </a-form-model>

          <div class="tool">
            <a-form layout="inline">
              <a-button @click="addRow" type="primary">
                <a-icon type="plus" /> 添加群
              </a-button>
            </a-form>
          </div>

          <table class="group-items">
            <thead>
              <th class="sort">排序</th>
              <th class="icon">图标</th>
              <th class="name">名称</th>
              <th class="desc">描述</th>
              <th class="qrcode">二维码</th>
              <th class="num">
                分享数
                <a-tooltip title="直接进群请填：0">
                  <a-icon type="question-circle-o" />
                </a-tooltip>
              </th>
              <th class="cnt">点击数</th>
              <th class="action">操作</th>
            </thead>
            <draggable
              :tag="'tbody'"
              v-model="form.json"
              handle=".drag-handler"
              @start="drag = true"
              @end="drag = false"
              v-bind="{
                animation: 200
              }"
            >
              <tr
                class="row"
                v-for="(item, index) in form.json"
                :key="item.sort"
              >
                <td class="dragzone" style="text-align:center;">
                  <a-icon type="menu" class="drag-handler" />
                </td>
                <td>
                  <a-upload
                    :data="{ index, idx: 1 }"
                    listType="picture-card"
                    :action="UPLOAD_URL"
                    :headers="{ t: getToken }"
                    withCredentials
                    accept="image/*"
                    :showUploadList="false"
                    @change="handleUpload"
                    :before-upload="beforeUpload"
                  >
                    <img
                      v-if="item.icon"
                      :src="item.icon"
                      style="width:50px;height:50px"
                    />
                    <div v-else>
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        上传
                      </div>
                    </div>
                  </a-upload>
                </td>
                <td>
                  <a-input v-model="item.name" :max-length="30" />
                </td>
                <td>
                  <a-input v-model="item.desc" :max-length="50" />
                </td>
                <td>
                  <a-upload
                    :data="{ index, idx: 2 }"
                    listType="picture-card"
                    :action="UPLOAD_URL"
                    :headers="{ t: getToken }"
                    withCredentials
                    accept="image/*"
                    :showUploadList="false"
                    @change="handleUpload"
                    :before-upload="beforeUpload"
                  >
                    <img
                      v-if="item.qrcode"
                      :src="item.qrcode"
                      style="width:50px;height:50px"
                    />
                    <div v-else>
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        上传
                      </div>
                    </div>
                  </a-upload>
                </td>
                <td>
                  <a-input-number v-model="item.num" :min="0" :max="100" />
                </td>
                <td>
                  {{ item.cnt }}
                </td>
                <td>
                  <a-button type="danger" @click="delRow(index)">
                    删除
                  </a-button>
                </td>
              </tr>
              <tr v-if="form.json.length <= 0">
                <td colspan="8">
                  <a-empty />
                </td>
              </tr>
            </draggable>
          </table>
        </a-spin>
      </div>
    </div>
  </Layout>
</template>

<script>
import draggable from "vuedraggable";

import ColorPicker from "@/components/color-picker";

import Layout from "@/layout/index.vue";

import uploadMixin from "@/mixins/upload";
import textMixin from "@/mixins/text";

const GroupSvc = require("@/service/group");

export default {
  name: "Group",
  data() {
    return {
      drag: false,
      spinning: false,

      form: {
        title: "",
        banner: "",
        bgColor: "#fff",
        btnColor: "#00c",
        shareTitle: "",
        shareImg: "",

        cnt: 0,

        json: []
      }
    };
  },

  computed: {},

  mixins: [uploadMixin, textMixin],

  methods: {
    btnStyle(item) {
      const { btnColor } = this.form;

      if (item.num > 0) {
        return {
          color: btnColor,
          background: "#fff",
          borderColor: btnColor
        };
      } else {
        return {
          color: "#fff",
          background: btnColor,
          borderColor: btnColor
        };
      }
    },

    addRow() {
      const sort = this.form.json.length + 1;

      // 默认图标
      const icon = `https://store.xinsailei.com/qun/${sort % 10}.jpg`;

      this.form.json.push({
        name: "XX群",
        desc: "群介绍",
        icon,
        qrcode: "",
        num: 3,
        sort,

        cnt: 0
      });
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url, index, idx } = data;

        switch (index) {
          // banner
          case 888:
            this.form.banner = url;
            break;
          // 分享封面
          case 999:
            this.form.shareImg = url;
            break;
          default:
            switch (idx) {
              case 1:
                this.form.json[index].icon = url;
                break;
              case 2:
                this.form.json[index].qrcode = url;
                break;
            }
        }
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },
    deleteImg(index) {
      // banner
      switch (index) {
        case 888:
          this.form.banner = "";
          break;
        // 分享封面
        case 999:
          this.form.shareImg = "";
          break;
      }
    },

    delRow(index) {
      this.form.json.splice(index, 1);
    },

    async submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let {
            title,
            banner,
            bgColor,
            btnColor,
            shareTitle,
            shareImg,

            json
          } = this.form;

          if (json.length <= 0) {
            this.$message.error("请添加至少一个群");
            return;
          }

          for (const row of json) {
            if (!row.name) {
              this.$message.error("请填写群名称");
              return;
            }
            if (!row.desc) {
              this.$message.error("请填写群描述");
              return;
            }
            if (!row.icon) {
              this.$message.error("请上传群图标");
              return;
            }
            if (!row.qrcode) {
              this.$message.error("请上传群二维码");
              return;
            }
          }

          json = JSON.stringify(json || []);

          try {
            let val = {
              title,
              banner,
              bgColor,
              btnColor,
              shareTitle,
              shareImg,

              json
            };

            await GroupSvc.createOrUpdate(val);
            this.$message.info("保存成功");
          } catch (err) {
            console.error(err);
            this.$message.error("保存群裂变失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    }
  },

  async mounted() {
    try {
      this.spinning = true;

      let res = await GroupSvc.get();
      let json = res.lines;

      this.form = {
        title: res.title || "",
        banner: res.banner || "",
        bgColor: res.bgColor || "",
        btnColor: res.btnColor || "#00c",
        shareTitle: res.shareTitle || "",
        shareImg: res.shareImg || "",

        cnt: res.cnt || 0,

        json
      };

      this.spinning = false;
    } catch (err) {
      console.error(err);
      this.$message.error("加载群裂变失败，错误：" + err.message);

      this.spinning = false;
    }
  },

  components: {
    Layout,
    draggable,
    ColorPicker
  }
};
</script>

<style lang="scss">
.app-group-list {
  display: flex;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;

  .right {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0;
    flex: 1;
  }

  .left {
    margin: 24px 0 24px 24px;
    width: 375px;
  }

  .frame {
    box-shadow: 0 0 10px #cecece;
    width: 375px;
    height: 80vh;
    max-height: 640px;
    border: 1px solid #f0f0f0;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .preview-body {
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .banner {
      width: 100%;
    }

    .wrap {
      margin: 20px 10px 100px;
      padding: 10px;
      background: #fff;
      border-radius: 16px;
      overflow: hidden;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        margin: 0;
        border: none;
      }

      .icn {
        width: 60px;
        height: 60px;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 20px;
      }
      .txt {
        flex: 1;
      }
      .name {
        font-size: 16px;
        font-weight: bold;
        line-height: 2em;
      }
      .desc {
        font-size: 12px;
        line-height: 2em;
        color: #999;
      }
      .btn {
        font-size: 13px;
        line-height: 1.5em;
        padding: 5px 10px;
        border-radius: 4px;
        color: #00c;
        border: 1px solid #00c;
        cursor: pointer;
        margin-left: 10px;

        &.active {
          background: #00c;
          color: #fff;
        }
      }
    }
  }

  .adv-form {
    padding: 14px;
    margin-bottom: 10px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
  }

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 70px;
    height: 70px;
    margin: 0;
  }

  .upload-image-small {
    width: 40px;
    height: 32px;
    margin-right: 10px;
  }

  .group-items {
    width: 100%;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 24px;

    .dragzone {
      .drag-handler {
        transform: scale(2);
        cursor: pointer;
      }
    }

    tr {
      background: #fff;
      transition: background 0.3s;

      &:hover {
        background: #e6f7ff;
      }
    }

    th {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      text-align: left;
      background: #fafafa;
      border-bottom: 1px solid #e8e8e8;
      transition: background 0.3s ease;
      white-space: nowrap;
      height: 53px;
    }

    th,
    td {
      padding: 16px;
      transition: background 0.3s;
      border-bottom: 1px solid #e8e8e8;
      white-space: nowrap;

      .ant-select {
        width: 100%;
        max-width: 300px;
      }

      .ant-input-number {
        width: 80px;
      }
    }

    .sort {
      width: 62px;
    }
    .icon {
      width: 84px;
    }
    .qrcode {
      width: 84px;
    }
    .name {
      width: 150px;
    }
    .desc {
    }
    .num {
      width: 60px;
    }
    .cnt {
      width: 60px;
    }
    .action {
      width: 60px;
    }
  }
}
</style>
